import {Alert, Button, Form, InputGroup, Modal} from "react-bootstrap";
import {useCallback, useContext, useEffect, useReducer, useState} from "react";
import "./style.scss"
import Confetti from 'react-confetti'
import * as yup from "yup";
import {Formik} from "formik";
import {Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import {handleEqualAndGreaterZeroMax100Change} from "../utils";
import {AppStateContext} from "../../App";
import EmailAutomationAPIs, {iDiscountCouponResponse} from "../../apis/Email/email.automations.apis";
import useIsMounted from "ismounted";
import {Reducer} from "redux";
import getting_started from "../../assets/images/getting_started.jpeg"
import {
    failed_action_response,
    failed_block_action_response,
    iResource,
    iResponseActions,
    loading_action_response,
    responseReducer,
    success_action_response
} from "../../redux/reducers";
import AppLoader from "../Loader/AppLoader";
import {HandleErrors} from "../helper/form.helper";


export default function GettingStarted() {
    const [show, setShow] = useState(true);
    const [page, setPage] = useState(1);
    const {shop} = useContext(AppStateContext);
    const isMounted = useIsMounted();
    const [skipping, setSkipping] = useState<boolean>();
    const [{
        response,
        error,
        loading,
        error_block
    }, dispatchResponse] = useReducer<Reducer<iResource<iDiscountCouponResponse>, iResponseActions<iDiscountCouponResponse>>>
    (responseReducer<iResource<iDiscountCouponResponse>, any>({}), {loading: true});
    const loadResource = useCallback(() => {
        dispatchResponse(loading_action_response())
        new EmailAutomationAPIs().load_default_discount_coupons().then((res => {
            if (isMounted.current) {
                if (EmailAutomationAPIs.hasError(res)) {
                    dispatchResponse(failed_block_action_response(res.message))
                } else {
                    dispatchResponse(success_action_response(res))
                }
            }
        }))
    }, [])
    const skipAutomation = useCallback(() => {
        setSkipping(true)
        new EmailAutomationAPIs().skip_automations().then((res => {
            if (isMounted.current) {
                setSkipping(false)
                if (EmailAutomationAPIs.hasError(res)) {
                    dispatchResponse(failed_block_action_response(res.message))
                } else {
                    setShow(false)
                }
            }
        }))
    }, [])

    useEffect(() => {
        if (shop && shop.default_automations_created === 0) {
            loadResource()
        }
    }, [shop])


    if (shop && shop.default_automations_created !== 0) return null;
    return <Modal show={show} className="getting-started" size="xl" centered>
        <Modal.Body>
            {
                page === 1 && <>
                    <Confetti
                        width={600}
                        height={500}
                        numberOfPieces={25}
                        className="w-100 h-100"
                    />
                    <div className="getting-started-main">
                        <div className="getting-started-image">
                            <div className="getting-started-wrapper">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 400 400"
                                     className="getting-started-image-svg"
                                     xmlSpace="preserve">
                                    <g className="confetti-cone">
                                        <path className="conf0"
                                              d="M131.5,172.6L196,343c2.3,6.1,11,6.1,13.4,0l65.5-170.7L131.5,172.6z"/>
                                        <path className="conf1"
                                              d="M131.5,172.6L196,343c2.3,6.1,11,6.1,13.4,0l6.7-17.5l-53.6-152.9L131.5,172.6z"/>

                                        <path className="conf2" d="M274.2,184.2c-1.8,1.8-4.2,2.9-7,2.9l-129.5,0.4c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.8,9.9-9.9l129.5-0.4
     				c5.4,0,9.8,4.4,9.8,9.8C277,180,275.9,182.5,274.2,184.2z"/>
                                        <polygon className="conf3"
                                                 points="231.5,285.4 174.2,285.5 143.8,205.1 262.7,204.7 			"/>
                                        <path className="conf4" d="M166.3,187.4l-28.6,0.1c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.8,9.9-9.9l24.1-0.1c0,0-2.6,5-1.3,10.6
     				C161.8,183.7,166.3,187.4,166.3,187.4z"/>
                                        <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -89.8523 231.0278)"
                                                 className="conf2" cx="233.9"
                                                 cy="224" rx="5.6" ry="5.6"/>
                                        <path className="conf5" d="M143.8,205.1l5.4,14.3c6.8-2.1,14.4-0.5,19.7,4.8c7.7,7.7,7.6,20.1-0.1,27.8c-1.7,1.7-3.7,3-5.8,4l11.1,29.4
     				l27.7,0l-28-80.5L143.8,205.1z"/>
                                        <path className="conf2"
                                              d="M169,224.2c-5.3-5.3-13-6.9-19.7-4.8l13.9,36.7c2.1-1,4.1-2.3,5.8-4C176.6,244.4,176.6,231.9,169,224.2z"/>
                                        <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -119.0946 221.1253)"
                                                 className="conf6" cx="207.4"
                                                 cy="254.3" rx="11.3" ry="11.2"/>
                                    </g>

                                    <rect x="113.7" y="135.7"
                                          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -99.5348 209.1582)"
                                          className="conf7" width="178" height="178"/>
                                    <line className="conf7" x1="76.8" y1="224.7" x2="328.6" y2="224.7"/>
                                    <polyline className="conf7" points="202.7,350.6 202.7,167.5 202.7,98.9 	"/>


                                    <circle className="conf2" id="b1" cx="195.2" cy="232.6" r="5.1"/>
                                    <circle className="conf0" id="b2" cx="230.8" cy="219.8" r="5.4"/>
                                    <circle className="conf0" id="c2" cx="178.9" cy="160.4" r="4.2"/>
                                    <circle className="conf6" id="d2" cx="132.8" cy="123.6" r="5.4"/>
                                    <circle className="conf0" id="d3" cx="151.9" cy="105.1" r="5.4"/>

                                    <path className="conf0" id="d1" d="M129.9,176.1l-5.7,1.3c-1.6,0.4-2.2,2.3-1.1,3.5l3.8,4.2c1.1,1.2,3.1,0.8,3.6-0.7l1.9-5.5
      		C132.9,177.3,131.5,175.7,129.9,176.1z"/>
                                    <path className="conf6" id="b5" d="M284.5,170.7l-5.4,1.2c-1.5,0.3-2.1,2.2-1,3.3l3.6,3.9c1,1.1,2.9,0.8,3.4-0.7l1.8-5.2
      		C287.4,171.9,286.1,170.4,284.5,170.7z"/>
                                    <circle className="conf6" id="c3" cx="206.7" cy="144.4" r="4.5"/>
                                    <path className="conf2" id="c1" d="M176.4,192.3h-3.2c-1.6,0-2.9-1.3-2.9-2.9v-3.2c0-1.6,1.3-2.9,2.9-2.9h3.2c1.6,0,2.9,1.3,2.9,2.9v3.2
      		C179.3,191,178,192.3,176.4,192.3z"/>
                                    <path className="conf2" id="b4" d="M263.7,197.4h-3.2c-1.6,0-2.9-1.3-2.9-2.9v-3.2c0-1.6,1.3-2.9,2.9-2.9h3.2c1.6,0,2.9,1.3,2.9,2.9v3.2
      		C266.5,196.1,265.2,197.4,263.7,197.4z"/>

                                    <path id="yellow-strip" d="M179.7,102.4c0,0,6.6,15.3-2.3,25c-8.9,9.7-24.5,9.7-29.7,15.6c-5.2,5.9-0.7,18.6,3.7,28.2
      		c4.5,9.7,2.2,23-10.4,28.2"/>
                                    <path className="conf8" id="yellow-strip"
                                          d="M252.2,156.1c0,0-16.9-3.5-28.8,2.4c-11.9,5.9-14.9,17.8-16.4,29c-1.5,11.1-4.3,28.8-31.5,33.4"/>
                                    <path className="conf0" id="a1" d="M277.5,254.8h-3.2c-1.6,0-2.9-1.3-2.9-2.9v-3.2c0-1.6,1.3-2.9,2.9-2.9h3.2c1.6,0,2.9,1.3,2.9,2.9v3.2
      		C280.4,253.5,279.1,254.8,277.5,254.8z"/>
                                    <path className="conf3" id="c4" d="M215.2,121.3L215.2,121.3c0.3,0.6,0.8,1,1.5,1.1l0,0c1.6,0.2,2.2,2.2,1.1,3.3l0,0c-0.5,0.4-0.7,1.1-0.6,1.7v0
      		c0.3,1.6-1.4,2.8-2.8,2l0,0c-0.6-0.3-1.2-0.3-1.8,0h0c-1.4,0.7-3.1-0.5-2.8-2v0c0.1-0.6-0.1-1.3-0.6-1.7l0,0
      		c-1.1-1.1-0.5-3.1,1.1-3.3l0,0c0.6-0.1,1.2-0.5,1.5-1.1v0C212.5,119.8,214.5,119.8,215.2,121.3z"/>
                                    <path className="conf3" id="b3" d="M224.5,191.7L224.5,191.7c0.3,0.6,0.8,1,1.5,1.1l0,0c1.6,0.2,2.2,2.2,1.1,3.3v0c-0.5,0.4-0.7,1.1-0.6,1.7l0,0
      		c0.3,1.6-1.4,2.8-2.8,2h0c-0.6-0.3-1.2-0.3-1.8,0l0,0c-1.4,0.7-3.1-0.5-2.8-2l0,0c0.1-0.6-0.1-1.3-0.6-1.7v0
      		c-1.1-1.1-0.5-3.1,1.1-3.3l0,0c0.6-0.1,1.2-0.5,1.5-1.1l0,0C221.7,190.2,223.8,190.2,224.5,191.7z"/>
                                    <path className="conf3" id="a2" d="M312.6,242.1L312.6,242.1c0.3,0.6,0.8,1,1.5,1.1l0,0c1.6,0.2,2.2,2.2,1.1,3.3l0,0c-0.5,0.4-0.7,1.1-0.6,1.7v0
      		c0.3,1.6-1.4,2.8-2.8,2l0,0c-0.6-0.3-1.2-0.3-1.8,0h0c-1.4,0.7-3.1-0.5-2.8-2v0c0.1-0.6-0.1-1.3-0.6-1.7l0,0
      		c-1.1-1.1-0.5-3.1,1.1-3.3l0,0c0.6-0.1,1.2-0.5,1.5-1.1v0C309.9,240.6,311.9,240.6,312.6,242.1z"/>
                                    <path className="conf8" id="yellow-strip" d="M290.7,215.4c0,0-14.4-3.4-22.6,2.7c-8.2,6.2-8.2,23.3-17.1,29.4c-8.9,6.2-19.8-2.7-32.2-4.1
      		c-12.3-1.4-19.2,5.5-20.5,10.9"/>

                                </svg>
                            </div>
                        </div>
                        <div className={"getting-started-content"}>
                            <div>
                                <h4 className="text-center">
                                    Welcome to Emailwish!
                                </h4>
                                <p className="text-center">
                                    You are just one step away from more sales.
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button
                                    onClick={() => {
                                        setPage(2)
                                    }}
                                    variant="primary"
                                    className="getting-started-lets-go">
                                    Let's Start!
                                </Button>
                            </div>

                        </div>
                    </div>
                </>
            }
            {
                page === 2 && <div className="getting-started-main automation-getting-started">
                    <div className="automation-getting-started-image">
                        <img src={getting_started} alt="a"/>
                    </div>
                    {
                        loading && <div className="p-2 d-flex justify-content-center w-100">
                            <AppLoader/>
                        </div>
                    }
                    {
                        error_block && <div className="p-2 w-100">
                            <Alert variant="warning">{error_block}</Alert>
                        </div>
                    }
                    {
                        !loading && !error_block && response && response.default_discounts &&
                        <div className="automation-getting-started-content">
                            <Formik
                                initialValues={{
                                    enable_automation: true,
                                    use_defaults: false,
                                    abandoned_cart_10: (response && response.default_discounts && response.default_discounts.abandoned_cart_10) || "",
                                    abandoned_cart_20: (response && response.default_discounts && response.default_discounts.abandoned_cart_20) || "",
                                    popup_discount_20: (response && response.default_discounts && response.default_discounts.popup_discount_20) || "",
                                    apology: (response && response.default_discounts && response.default_discounts.apology) || "",
                                    review_with_no_image_10: (response && response.default_discounts &&
                                        response.default_discounts &&
                                        response.default_discounts.review_with_no_image_10) || "",
                                    review_with_image_20: (response && response.default_discounts &&
                                        response.default_discounts.review_with_image_20) || "",
                                }}
                                onSubmit={((values, formikHelpers) => {
                                    if (values.enable_automation) {
                                        new EmailAutomationAPIs().default_automations_create_automations(values).then((res) => {
                                            if (isMounted.current) {
                                                if (EmailAutomationAPIs.hasError(res)) {
                                                    if (!HandleErrors(res, formikHelpers)) {
                                                        dispatchResponse(failed_action_response(res.message))
                                                    }

                                                } else {
                                                    setShow(false)
                                                }
                                            }
                                        })
                                    } else {
                                        skipAutomation()
                                    }

                                })}
                                validationSchema={yup.object({
                                    use_defaults: yup.boolean().required("Please select one option ")

                                })}>
                                {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      touched,
                                      isSubmitting,
                                      setFieldValue,
                                      errors,
                                  }: any) => {
                                    return <form onSubmit={handleSubmit}>
                                        <Box flex={1}>
                                            <Typography variant={"h4"} align="center" color="primary"
                                                        className="automation-getting-started-content-title">
                                                Email Automation Configurations
                                            </Typography>
                                            <Box flex={1} paddingTop={1}>
                                                <div className="automation-getting-started-content-question">
                                                    <Typography variant="body1"
                                                                className="automation-getting-started-content-question-body">
                                                        Do you want to enable ready made automations now?
                                                    </Typography>
                                                    <div className="p-2"/>
                                                    <FormControl component="fieldset" className="d-flex"
                                                                 error={errors.enable_automation}>
                                                        <RadioGroup row aria-label="position" name="position"
                                                                    defaultValue={"yes"}>
                                                            <FormControlLabel
                                                                value="yes"
                                                                onChange={((event, checked) => {
                                                                    if (checked) {
                                                                        setFieldValue("enable_automation", checked)
                                                                    }
                                                                })}
                                                                control={<Radio color="primary"/>}
                                                                label="Yes"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="no"
                                                                onChange={((event, checked) => {
                                                                    if (checked) {
                                                                        setFieldValue("enable_automation", !checked)
                                                                    }
                                                                })}
                                                                control={<Radio color="primary"/>}
                                                                label="No"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>


                                                {
                                                    values.enable_automation &&
                                                    <>
                                                        <Typography className="automation-getting-started-info-text">
                                                            Email automations are emails which are sent automatically
                                                            when certain conditions are fulfilled.
                                                            We need some discount values from you to create these
                                                            emails. These discounts will be sent to your customer to
                                                            increase your sales.
                                                        </Typography>
                                                        <Grid container className="mb-2">
                                                            {
                                                                values.abandoned_cart_10 && <Grid xs={12}
                                                                                                  item
                                                                                                  className="px-2">
                                                                    <div
                                                                        className="automation-getting-started-tiles">
                                                                        <div
                                                                            className="automation-getting-started-tile">
                                                                            <div>
                                                                                <h6>
                                                                                    {
                                                                                        values?.abandoned_cart_10?.label
                                                                                    }
                                                                                </h6>
                                                                            </div>
                                                                            <div>
                                                                                <InputGroup
                                                                                    className="m-0"
                                                                                    style={{width: "100px"}}>
                                                                                    <Form.Control
                                                                                        name={`abandoned_cart_10.discount`}
                                                                                        value={values.abandoned_cart_10.discount}
                                                                                        onChange={event => {
                                                                                            handleEqualAndGreaterZeroMax100Change(event, handleChange)
                                                                                        }}
                                                                                        isInvalid={
                                                                                            touched &&
                                                                                            touched.abandoned_cart_10 &&
                                                                                            touched.abandoned_cart_10.discount &&
                                                                                            !!(errors &&
                                                                                                errors.abandoned_cart_10 &&
                                                                                                errors.abandoned_cart_10.discount
                                                                                            )
                                                                                        }
                                                                                        type="number"
                                                                                    >
                                                                                    </Form.Control>
                                                                                    <InputGroup.Text
                                                                                        style={{
                                                                                            border: 0,
                                                                                            background: "unset"
                                                                                        }}>%</InputGroup.Text>
                                                                                    <Form.Control.Feedback
                                                                                        type="invalid">
                                                                                        {errors &&
                                                                                        errors.abandoned_cart_10 &&
                                                                                        errors.abandoned_cart_10.discount}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {
                                                                values.abandoned_cart_20 && <Grid xs={12}
                                                                                                  item
                                                                                                  className="px-2">
                                                                    <div
                                                                        className="automation-getting-started-tiles">
                                                                        <div
                                                                            className="automation-getting-started-tile">
                                                                            <div>
                                                                                <h6>
                                                                                    {
                                                                                        values?.abandoned_cart_20?.label
                                                                                    }
                                                                                </h6>
                                                                            </div>
                                                                            <div>
                                                                                <InputGroup
                                                                                    className="m-0"
                                                                                    style={{width: "100px"}}>
                                                                                    <Form.Control
                                                                                        name={`abandoned_cart_20.discount`}
                                                                                        value={values.abandoned_cart_20.discount}
                                                                                        onChange={event => {
                                                                                            handleEqualAndGreaterZeroMax100Change(event, handleChange)
                                                                                        }}
                                                                                        isInvalid={
                                                                                            touched &&
                                                                                            touched.abandoned_cart_20 &&
                                                                                            touched.abandoned_cart_20.discount &&
                                                                                            !!(errors &&
                                                                                                errors.abandoned_cart_20 &&
                                                                                                errors.abandoned_cart_20.discount
                                                                                            )
                                                                                        }
                                                                                        type="number"
                                                                                    >
                                                                                    </Form.Control>
                                                                                    <InputGroup.Text
                                                                                        style={{
                                                                                            border: 0,
                                                                                            background: "unset"
                                                                                        }}>%</InputGroup.Text>
                                                                                    <Form.Control.Feedback
                                                                                        type="invalid">
                                                                                        {errors &&
                                                                                        errors.abandoned_cart_20 &&
                                                                                        errors.abandoned_cart_20.discount}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {
                                                                values.apology && <Grid xs={12}
                                                                                        item
                                                                                        className="px-2">
                                                                    <div
                                                                        className="automation-getting-started-tiles">
                                                                        <div
                                                                            className="automation-getting-started-tile">
                                                                            <div>
                                                                                <h6>
                                                                                    {
                                                                                        values?.apology?.label
                                                                                    }
                                                                                </h6>
                                                                            </div>
                                                                            <div>
                                                                                <InputGroup
                                                                                    className="m-0"
                                                                                    style={{width: "100px"}}>
                                                                                    <Form.Control
                                                                                        name={`apology.discount`}
                                                                                        value={values.apology.discount}
                                                                                        onChange={event => {
                                                                                            handleEqualAndGreaterZeroMax100Change(event, handleChange)
                                                                                        }}
                                                                                        isInvalid={
                                                                                            touched &&
                                                                                            touched.apology &&
                                                                                            touched.apology.discount &&
                                                                                            !!(errors &&
                                                                                                errors.apology &&
                                                                                                errors.apology.discount
                                                                                            )
                                                                                        }
                                                                                        type="number"
                                                                                    >
                                                                                    </Form.Control>
                                                                                    <InputGroup.Text
                                                                                        style={{
                                                                                            border: 0,
                                                                                            background: "unset"
                                                                                        }}>%</InputGroup.Text>
                                                                                    <Form.Control.Feedback
                                                                                        type="invalid">
                                                                                        {errors &&
                                                                                        errors.apology &&
                                                                                        errors.apology.discount}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {
                                                                values.popup_discount_20 && <Grid xs={12}
                                                                                                  item
                                                                                                  className="px-2">
                                                                    <div
                                                                        className="automation-getting-started-tiles">
                                                                        <div
                                                                            className="automation-getting-started-tile">
                                                                            <div>
                                                                                <h6>
                                                                                    {
                                                                                        values?.popup_discount_20?.label
                                                                                    }
                                                                                </h6>
                                                                            </div>
                                                                            <div>
                                                                                <InputGroup
                                                                                    className="m-0"
                                                                                    style={{width: "100px"}}>
                                                                                    <Form.Control
                                                                                        name={`popup_discount_20.discount`}
                                                                                        value={values.popup_discount_20.discount}
                                                                                        onChange={event => {
                                                                                            handleEqualAndGreaterZeroMax100Change(event, handleChange)
                                                                                        }}
                                                                                        isInvalid={
                                                                                            touched &&
                                                                                            touched.popup_discount_20 &&
                                                                                            touched.popup_discount_20.discount &&
                                                                                            !!(errors &&
                                                                                                errors.popup_discount_20 &&
                                                                                                errors.popup_discount_20.discount
                                                                                            )
                                                                                        }
                                                                                        type="number"
                                                                                    >
                                                                                    </Form.Control>
                                                                                    <InputGroup.Text
                                                                                        style={{
                                                                                            border: 0,
                                                                                            background: "unset"
                                                                                        }}>%</InputGroup.Text>
                                                                                    <Form.Control.Feedback
                                                                                        type="invalid">
                                                                                        {errors &&
                                                                                        errors.popup_discount_20 &&
                                                                                        errors.popup_discount_20.discount}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {
                                                                values.review_with_no_image_10 && <Grid xs={12}
                                                                                                        item
                                                                                                        className="px-2">
                                                                    <div
                                                                        className="automation-getting-started-tiles">
                                                                        <div
                                                                            className="automation-getting-started-tile">
                                                                            <div>
                                                                                <h6>
                                                                                    {
                                                                                        values?.review_with_no_image_10?.label
                                                                                    }
                                                                                </h6>
                                                                            </div>
                                                                            <div>
                                                                                <InputGroup
                                                                                    className="m-0"
                                                                                    style={{width: "100px"}}>
                                                                                    <Form.Control
                                                                                        name={`review_with_no_image_10.discount`}
                                                                                        value={values.review_with_no_image_10.discount}
                                                                                        onChange={event => {
                                                                                            handleEqualAndGreaterZeroMax100Change(event, handleChange)
                                                                                        }}
                                                                                        isInvalid={
                                                                                            touched &&
                                                                                            touched.review_with_no_image_10 &&
                                                                                            touched.review_with_no_image_10.discount &&
                                                                                            !!(errors &&
                                                                                                errors.review_with_no_image_10 &&
                                                                                                errors.review_with_no_image_10.discount
                                                                                            )
                                                                                        }
                                                                                        type="number"
                                                                                    >
                                                                                    </Form.Control>
                                                                                    <InputGroup.Text
                                                                                        style={{
                                                                                            border: 0,
                                                                                            background: "unset"
                                                                                        }}>%</InputGroup.Text>
                                                                                    <Form.Control.Feedback
                                                                                        type="invalid">
                                                                                        {errors &&
                                                                                        errors.review_with_no_image_10 &&
                                                                                        errors.review_with_no_image_10.discount}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {
                                                                values.review_with_image_20 && <Grid xs={12}
                                                                                                        item
                                                                                                        className="px-2">
                                                                    <div
                                                                        className="automation-getting-started-tiles">
                                                                        <div
                                                                            className="automation-getting-started-tile">
                                                                            <div>
                                                                                <h6>
                                                                                    {
                                                                                        values?.review_with_image_20?.label
                                                                                    }
                                                                                </h6>
                                                                            </div>
                                                                            <div>
                                                                                <InputGroup
                                                                                    className="m-0"
                                                                                    style={{width: "100px"}}>
                                                                                    <Form.Control
                                                                                        name={`review_with_image_20.discount`}
                                                                                        value={values.review_with_image_20.discount}
                                                                                        onChange={event => {
                                                                                            handleEqualAndGreaterZeroMax100Change(event, handleChange)
                                                                                        }}
                                                                                        isInvalid={
                                                                                            touched &&
                                                                                            touched.review_with_image_20 &&
                                                                                            touched.review_with_image_20.discount &&
                                                                                            !!(errors &&
                                                                                                errors.review_with_image_20 &&
                                                                                                errors.review_with_image_20.discount
                                                                                            )
                                                                                        }
                                                                                        type="number"
                                                                                    >
                                                                                    </Form.Control>
                                                                                    <InputGroup.Text
                                                                                        style={{
                                                                                            border: 0,
                                                                                            background: "unset"
                                                                                        }}>%</InputGroup.Text>
                                                                                    <Form.Control.Feedback
                                                                                        type="invalid">
                                                                                        {errors &&
                                                                                        errors.review_with_image_20 &&
                                                                                        errors.review_with_image_20.discount}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </>
                                                }
                                            </Box>
                                            {
                                                error && <div>
                                                    <Alert variant="danger">
                                                        {error}
                                                    </Alert>
                                                </div>
                                            }
                                            <div className="w-100 d-flex justify-content-between">
                                                <div>
                                                    {
                                                        values.enable_automation &&
                                                        <Button variant="light" onClick={() => {
                                                            new EmailAutomationAPIs().default_automations_create_automations({use_defaults: true}).then((res) => {
                                                                if (isMounted.current) {
                                                                    if (EmailAutomationAPIs.hasError(res)) {
                                                                        dispatchResponse(failed_action_response(res.message))
                                                                    } else {
                                                                        setShow(false)
                                                                    }
                                                                }
                                                            })
                                                        }}>
                                                            <Typography variant={"button"} align="center"
                                                                        color="primary">
                                                                Skip and set default
                                                            </Typography>
                                                        </Button>
                                                    }
                                                </div>
                                                <div>
                                                    <Button variant="primary" type="submit">
                                                        <Typography variant={"button"} align="center"
                                                                    style={{color: "white"}}>
                                                            Save and Continue
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Box>
                                    </form>
                                }}
                            </Formik>
                        </div>
                    }
                </div>
            }

        </Modal.Body>
    </Modal>
}
